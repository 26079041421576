import {createRouter, createWebHistory} from 'vue-router'

const Home = () => import("../views/Home/Home");
const Categroy = () => import("../views/Categroy/Categroy");
const Detail = () => import("../views/Detail/Detail");
const Profile = () => import("../views/Profile/Profile");
const Shopcar = () => import("../views/Shopcar/Shopcar");

const routes = [
    {path: '/', redirect: '/home.html'},
    {path: '/home.html', name: 'Home', component: Home, meta: {title: '首页'}},
    {path: '/categroy.html', name: 'Categroy', component: Categroy, meta: {title: '分类'}},
    {path: '/detail.html', name: 'Detail', component: Detail, meta: {title: '商品详情'}},
    {path: '/user.html', name: 'Profile', component: Profile, meta: {title: '我的'}},
    {path: '/car.html', name: 'Shopcar', component: Shopcar, meta: {title: '购物车'}}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
// 路由守卫
router.beforeEach((to, from, next)=>{
    const title = to.meta && to.meta.title;
    if(title){
        document.title = title;
    }
    // 如果没有登录，在这里到登录页面
    next();
})
export default router
