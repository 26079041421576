<template>
    <router-view v-slot="{Component}">
        <transition>
            <keep-alive>
                <component :is="Component"></component>
            </keep-alive>
        </transition>
    </router-view>
    <nav>
        <router-link class="tab-bar-item" to="/">
            <div class="menu">
                <i class="iconfont icon-shouye"></i>
                <text>首页</text>
            </div>
        </router-link>
        <router-link class="tab-bar-item" to="/categroy.html">
            <div class="menu">
                <i class="iconfont icon-fenlei"></i>
                <text>分类</text>
            </div>
        </router-link>
        <router-link class="tab-bar-item" to="/car.html">
            <div class="menu">
                <van-badge :content="20" max="10">
                    <i class="iconfont icon-gouwuchezhengpin"></i>
                </van-badge>
                <text>购物车</text>
            </div>
        </router-link>
        <router-link class="tab-bar-item" to="/user.html">
            <div class="menu">
                <i class="iconfont icon-yonghu"></i>
                <text>我的</text>
            </div>
        </router-link>
    </nav>
</template>

<style lang="scss">
@import "assets/css/base.css";
@import "assets/css/iconfont.css";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0 ;
    box-shadow: 0 -3px 1px rgba(100,100,100,.1);
    .router-link-active{
        color: var(--color-hight);
    }
    .tab-bar-item{
        flex: 1;
        .menu{
            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: var(--font-size);
            i{
                font-size: 24px;
                margin-top: 2px;
                margin-bottom: 2px;
            }
        }
    }
}
</style>
<script setup>
</script>