import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'

import {Swipe,SwipeItem,Lazyload,Badge,Sidebar, SidebarItem,Collapse, CollapseItem} from 'vant'

createApp(App)
    .use(Swipe)// 轮播图
    .use(SwipeItem)
    .use(Sidebar)// 侧边导航
    .use(SidebarItem)
    .use(Collapse)// 折叠面板
    .use(CollapseItem)
    .use(Lazyload,{
        loading:require('./assets/images/default.png')
    })
    .use(Badge)
    .use(store).use(router).mount('#app')
